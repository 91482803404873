import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { UserContext } from '../providers/UserProvider.js'

import SEO from '../utils/SEO'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Header from '../components/Header';
import Footer from '../components/Footer';

import backgroundImage from "../assets/images/backgrounds/gambito_background.jpg";

export default function Completion() {

    const navigate = useNavigate()
    const { user, setUserData, activeButtons, setActiveButtons } = React.useContext(UserContext)

    useEffect(() => {

        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');
        const license = localStorage.getItem('license');
        const dni = localStorage.getItem('dni');

        setUserData({ email, password });
        setActiveButtons({ dni, license });


    }, [])


    return (
        <>

            <SEO title="Gambito | Registro" description="Gambito registry page" />

            <section className='completion' style={{ backgroundImage: `url(${backgroundImage})` }}>

                <div className='completion__overlay'>

                    <Grid container className="completion__content">
                        <Header />
                        <Grid item xs={12} className="text-col p-b-xl">
                            <p className='completion__title p-b-l'>¡Bienvenido!</p>
                            <Card className='completion__text m-b-xl'>
                                <p>Muchas gracias por formar parte del <b>Golf Exclusive Club.</b></p>

                                <p>Tu registro se ha completado exitosamente.</p>

                            </Card>

                        </Grid>

                    </Grid>
                    
                        <Card className='completion__footer p-l m-b-l'>
                            <Footer />

                        </Card>

                </div>

            </section>
        </>
    ) 
}
